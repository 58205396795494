<template>
  <PageWithLayout :isBodyBg="false">
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';

export default {
  name:'BannerList',
  components:{
    PageWithLayout,
  },
}
</script>